import * as React from "react";
import {Authentication, IrcStatus} from "../reducers/store";

interface StatusProps {
    auth: Authentication;
    status?: IrcStatus; // undefined when coming from Login, needs default?
}

export default class Status extends React.Component<StatusProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const {auth, status} = this.props;
        return (
            <div>
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
                <div>Loading...</div>
                <p>Authentication: {auth}</p>
            </div>
        );
    }
}