import * as React from 'react';
import ChatInput from "./components/chat-input";
import ChatHistory from "./components/chat-history";
import {DispatchFromProps, StateToProps} from "./containers/index";
import {Authentication, Connection, IrcStatus, Message} from "./reducers/store";
import Status from "./components/status";

export default class App extends React.Component<StateToProps & DispatchFromProps, {}> {


    constructor(props: StateToProps & DispatchFromProps) {
        super(props);
    }

    shouldComponentUpdate(nextProps: Readonly<StateToProps & DispatchFromProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return true;
    }

    render() {
        const {auth, messages, status, onMessage} : {auth: Authentication, messages: Message[], status: IrcStatus, onMessage: any} = this.props;
        switch (status.connection) {
            case Connection.JOINED:
                return (
                    <main>
                        <ChatHistory messages={messages}/>
                        <ChatInput auth={auth} status={status} sendMessage={onMessage}/>
                    </main>
                );
            default:
                return <Status status={status} auth={auth} />
        }
    }

}