import * as React from "react";
import {Authentication, IrcStatus} from "../reducers/store";

interface UserProps {
    status: IrcStatus;
    auth: Authentication;
    sendMessage: any;
}

export default class ChatInput extends React.Component<UserProps, {}> {
    private input: HTMLInputElement;

    constructor(props: any) {
        super(props);
        this.onMessage = this.onMessage.bind(this);    // This binding is necessary to make `this` work in the callback
    }

    onMessage(event: any): void {
        event.preventDefault();
        let message = this.input.value;
        if (message.length > 0) {
            this.props.sendMessage({user: this.props.status.nickname, message: message, date: new Date()});
            this.input.value = "";
            this.input.focus();
        }
    }


    componentDidMount(): void {
        this.input.focus();
    }

    render() {
        const {status, auth} = this.props;
        return (
            <footer>
                <form className="container" onSubmit={this.onMessage}>
                    <div className="row">
                        <div className="status col s1">
                            <a href="#" className="tooltipped" data-position="bottom" data-delay="50" data-tooltip="I am a tooltip">
                                <div title={`Status: ${auth} User: ${status.nickname}`} className={`message-avatar ${status.connection.toLowerCase()}`}>{status.nickname.charAt(0)}</div>
                            </a>
                        </div>
                        <div className="input-field col s9">
                            <input type="text" ref={(input) => {
                                this.input = input
                            }} placeholder="Type your message"/>
                        </div>
                        <div className="input-field col s2">
                            <button type="submit" className="waves-effect waves-light btn-floating btn-large">
                                <i className="material-icons">send</i>
                            </button>
                        </div>
                    </div>
                </form>
            </footer>
        );
    }
}
