import * as React from "react";
import {Authentication, IrcStatus} from "../reducers/store";
import {Redirect} from "react-router";
import Status from "./status";

interface LoginProps {
    auth: Authentication;
    status: IrcStatus;
    onLogin: any;
}

export default class Login extends React.Component<LoginProps, {}> {
    private password: any;
    private username: any;

    constructor(props: any) {
        super(props);
        this.onLogin = this.onLogin.bind(this);    // This binding is necessary to make `this` work in the callback
    }


    shouldComponentUpdate(nextProps: Readonly<LoginProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return true;
    }

    onLogin(event: any): void {
        event.preventDefault();
        this.props.onLogin(this.username.value, this.password.value);
    }

    render() {
        const {auth, status} : {auth: Authentication, status: IrcStatus} = this.props;

        switch (auth) {
            case Authentication.PENDING:
                // status not yet initialized
                return <Status status={status} auth={auth} />;
            case Authentication.SUCCESSFUL:
                return <Redirect to="/"/>;
            default:
                return (
                    <div className="login-container">
                        <form onSubmit={this.onLogin}>
                            <div className="input-field">
                                <input ref={(username) => {
                                    this.username = username
                                }} type="text" id="nickname" placeholder="Nickname" required/>
                            </div>
                            <div className="input-field">
                                <input ref={(password) => {
                                    this.password = password
                                }} type="password" id="password" placeholder="Password" required/>
                            </div>
                            <button className="btn waves-effect waves-light" type="submit">Connect</button>
                        </form>
                    </div>
                );
        }
    }
}