import * as React from "react";
import {Route} from 'react-router-dom'
import {connect} from 'react-redux'
import {RouteProps} from "react-router";
import {Authentication, MStore} from "../reducers/store";
import LoginContainer from "../containers/login-container";
import {Component} from "react";

export interface AuthorizedProps {
    auth: Authentication
}

class AuthorizedRoute extends Component<RouteProps & AuthorizedProps> {
// RouteProps & AuthorizedProps
    render() {
        // unused component extracted otherwise the following error:
        // You should not use <Route component> and <Route render> in the same route;
        // <Route render> will be ignored
        const {component: Component, auth, ...rest} = this.props;
        return (
            <Route {...rest} render={props => {
                switch (auth) {
                    case Authentication.SUCCESSFUL:
                        return <Component {...props} {...rest}/>;
                    default:
                        return <LoginContainer {...props} {...rest} />;
                }
            }}/>
        )
    }
}


const mapStateToProps = (state: MStore.All): AuthorizedProps => {
    return {
        auth: state.auth
    }
};

export const AuthorizedRouteContainer = connect<RouteProps & AuthorizedProps, any, any>(mapStateToProps)(AuthorizedRoute);
