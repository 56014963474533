import * as React from "react";
import * as ReactDOM from "react-dom";

import './styles/styles.scss';
import {Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {reducers} from "./reducers/reducers";
import {applyMiddleware, createStore, Store} from "redux";
import {createLogger} from 'redux-logger'
import {MStore} from "./reducers/store";
import {Provider} from "react-redux";
import AppContainer from "./containers/index";
import LoginContainer from "./containers/login-container";
import {AuthorizedRouteContainer} from "./routes/authorized-route";
import {default as middleware} from "./websocket/socket";

let protocol = (location.protocol == 'https:')? "wss": "ws";

const store: Store<MStore.All> = createStore<MStore.All, any, any, any>(reducers, applyMiddleware(
    middleware(protocol, window.location.host),
    createLogger() // neat middleware that logs actions
));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={() => <LoginContainer />}/>
                <AuthorizedRouteContainer path="/" component={() => <AppContainer />}/>
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById("app")
);


