import {Action} from '../actions'
import {Authentication, Connection, IrcStatus, Message, MStore} from "./store";
import {combineReducers} from "redux";
import All = MStore.All;

export function messagesReducer(state: Message[] = [], action: Action): Message[] {

    switch (action.type) {
        case 'SEND_MESSAGE':
        case 'RECEIVE_MESSAGE':
            let newMessages = state.slice();
            newMessages.push(action.message);
            console.log(newMessages);
            return newMessages;
        case 'HISTORY':
            let history = action.history;
            console.log(history);
            return history;
        default:
            return state;
    }
}

export function authReducer(auth: Authentication = Authentication.UNAUTHENTICATED, action: Action): Authentication {

    switch (action.type) {
        case 'LOGIN':
        case 'LOGIN_OK':
        case 'LOGIN_FAILED':
            return action.auth;
        default:
            return auth;
    }
}

export function ircReducer(status: IrcStatus = {nickname: "none", connection: Connection.DISCONNECTED}, action: Action): IrcStatus {

    switch (action.type) {
        case 'STATUS':
            return Object.assign({}, status, action.status);
        default:
            return status;
    }
}

// reducer needs the same name as the State-property. hence the mapping 'property: reducer'
export const reducers = combineReducers<All>({
    auth: authReducer, messages: messagesReducer, status: ircReducer
});