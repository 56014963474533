import * as React from "react";
import {Message} from "../reducers/store";
import "../types.d.ts";

interface HistoryProps {
    messages: Message[]
}

String.prototype.linkify = function (this : string) {
    // http://, https://
    let urlPattern = /\b(?:https?):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

    return this
        .replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
};

function Message(props: {className: string, children: string}) {
    return <p className={props.className} dangerouslySetInnerHTML={{ __html: props.children.linkify()}} />;
}

export default class ChatHistory extends React.Component<HistoryProps, {}> {
    private scrollMarker: any;

    constructor(props: any) {
        super(props);
    }

    componentDidUpdate() {
        this.scrollMarker.scrollIntoView({ behavior: "smooth" });
        console.log("scroll");
    }

    render(): any {
        const {messages} = this.props;

        return (
            <section>
                <ul className="messages">
                    {messages.map((message) => {
                        const messageTime = message.date.toLocaleTimeString('de-CH', {hour: 'numeric', minute: 'numeric'});
                        return (
                            <li className="message-wrapper" key={message.date.getMilliseconds()}>
                                <div className="message-avatar">{message.user.charAt(0)}</div>
                                <span className="message-meta">{message.user} @ {messageTime}</span>
                                <Message className="message">{message.message}</Message>
                            </li>
                        );
                    })}
                </ul>
                <div style={{ float:"left", clear: "both" }} ref={(span: any) => {
                    this.scrollMarker = span;
                }}></div>
            </section>
        );
    }
}
