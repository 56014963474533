import {Authentication, IrcStatus, Message} from "../reducers/store";

export type Action = {
    type: 'SEND_MESSAGE',
    message: Message;
} | {
    type: 'RECEIVE_MESSAGE',
    message: Message;
} | {
    type: 'HISTORY',
    history: Message[];
} | {
    type: 'LOGIN',
    user: string,
    password: string,
    auth: Authentication;
} | {
    type: 'LOGIN_OK',
    auth: Authentication;
} | {
    type: 'LOGIN_FAILED',
    auth: Authentication;
} | {
    type: 'SEND_SOCKET',
    message: Message;
} | {
    type: 'KEEPALIVE';
} | {
    type: 'STATUS',
    status: IrcStatus;
}


export const sendMessage = (message: Message): Action => ({
    type: 'SEND_MESSAGE',
    message
});

export const login = (user: string, password: string): Action => ({
    type: 'LOGIN',
    user: user,
    password: password,
    auth: Authentication.PENDING // enum not working?
});
