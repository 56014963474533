import {connect} from "react-redux";
import {login} from "../actions/index";
import {Authentication, MStore} from "../reducers/store";
import Login from "../components/login";
import {withRouter} from "react-router";


const mapStateToProps = (status: MStore.All): StateToProps => {
    return {
        auth: status.auth
    }
};

const mapDispatchToProps = (dispatch: any): DispatchFromProps => {
    return {
        onLogin: (user: string, password: string) => {
            dispatch(login(user, password))
        }
    }
};

export interface StateToProps {
    auth: Authentication
}

export interface DispatchFromProps {
    onLogin: any;
}

const LoginContainer = withRouter(connect<StateToProps, DispatchFromProps, any>(mapStateToProps, mapDispatchToProps)(Login));

export default LoginContainer;