import {Action} from "../actions/index";
import {Dispatch, MiddlewareAPI} from "redux";
import {Authentication, IrcStatus} from "../reducers/store";

let timerId: any = 0;

function keepAlive(socket: WebSocket) {
    var timeout = 20000;
    if (socket.readyState == socket.OPEN) {
        socket.send(JSON.stringify({type: 'KEEPALIVE'}));
    }
    timerId = setTimeout(keepAlive, timeout, socket);
}

function cancelKeepAlive() {
    if (timerId) {
        clearTimeout(timerId);
    }
}

let onMessage = (api: MiddlewareAPI<any>) => {
    return function (event: MessageEvent) {
        api.dispatch(messageToActionAdapter(event));
    }
};

let onOpen = (user: string, password: string) => {
    return function () {
        let socket: WebSocket = this;
        socket.send(JSON.stringify({type: 'LOGIN', payload: {user: user, password: password}}));
        keepAlive(socket);
    }
};

let onReOpen = (user: string, password: string) => {
    return function () {
        let socket: WebSocket = this;
        socket.send(JSON.stringify({type: 'RE_CONNECT', payload: {user: user, password: password}}));
        keepAlive(socket);
    }
};

let onClose = () => {
    console.log("Socket closed.");
    cancelKeepAlive();
};

let onError = (protocol: string, host: string, user: string, password: string, api: MiddlewareAPI<any>) => {
    return function (event: Event) {
        console.log(`Socket ERROR: ${event}`);
        setTimeout(function () {
            connect(protocol, host, {
                onError: onError(protocol, host, user, password, api),
                onOpen: onReOpen,
                onMessage: onMessage(api),
                onClose: onClose
            });
        }, 1000);
    }
};

interface Callbacks {
    onError: any;
    onOpen: any;
    onMessage: any;
    onClose: any;
}


function middleware(protocol: string, host: string): any {
    let socket: WebSocket;

    return (api: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
        switch (action.type) {
            case 'LOGIN':
                socket = connect(protocol, host, {
                    onError: onError(protocol, host, action.user, action.password, api),
                    onOpen: onOpen(action.user, action.password),
                    onMessage: onMessage(api),
                    onClose: onClose
                });
                break;
            case 'SEND_MESSAGE':
                socket.send(JSON.stringify({
                    type: 'MESSAGE',
                    payload: {user: action.message.user, message: action.message.message}
                }));
                break;
        }
        return next(action);
    }
}

function connect(protocol: string, host: string, callbacks: Callbacks): WebSocket {
    let socket = new WebSocket(`${protocol}://${host}/websocket`);
    socket.onopen = callbacks.onOpen;
    socket.onclose = callbacks.onClose;
    socket.onerror = callbacks.onError;
    socket.onmessage = callbacks.onMessage;
    return socket;
}

const eventToActionAdapters: { [id: string]: (payload: SocketData | IrcStatus) => Action } = {
    'MESSAGE': (payload: SocketData) => ({
        type: 'RECEIVE_MESSAGE',
        message: transform(payload.message)
    }),
    'HISTORY': (payload: SocketData) => ({
        type: 'HISTORY',
        history: payload.history.map(message => transform(message))
    }),
    'LOGIN_OK': () => ({type: 'LOGIN_OK', auth: Authentication.SUCCESSFUL}),
    'LOGIN_FAILED': () => ({type: 'LOGIN_FAILED', auth: Authentication.FAILED}),
    'KEEPALIVE': () => ({type: 'KEEPALIVE'}),
    'STATUS': (payload: IrcStatus) => ({type: 'STATUS', status: payload})
};

function messageToActionAdapter(socketEvent: MessageEvent) {
    let type: string = JSON.parse(socketEvent.data).type;
    let payload: SocketData = JSON.parse(socketEvent.data).payload;

    if (eventToActionAdapters[type]) {
        return eventToActionAdapters[type](payload);
    }
}

interface Message {
    message: string,
    user: string,
    timestamp: number
}

interface SocketData {
    nickname: string;
    message: Message,
    history: Message[]
}

/**
 * Extends the message object with a new message.date property from message.timestamp.
 */
function transform(message: Message) : Message & {date: Date} {
    return Object.assign({}, message, {message: message.message, date: new Date(message.timestamp)})
}

export default middleware;
