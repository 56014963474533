import App from "../app";
import {connect} from "react-redux";
import {sendMessage} from "../actions/index";
import {Authentication, IrcStatus, Message, MStore} from "../reducers/store";
import {withRouter} from "react-router";


const mapStateToProps = (state: MStore.All): StateToProps => {
    return {
        auth: state.auth,
        messages: state.messages,
        status: state.status
    }
};


const mapDispatchToProps = (dispatch: any): DispatchFromProps => {
    return {
        onMessage: (message: Message) => {
            dispatch(sendMessage(message))
        }
    }
};

export interface StateToProps {
    auth: Authentication,
    messages: Message[],
    status: IrcStatus
}

export interface DispatchFromProps {
    onMessage: any;
}

const AppContainer = withRouter(connect<StateToProps, DispatchFromProps, any>(mapStateToProps, mapDispatchToProps)(App));

export default AppContainer;